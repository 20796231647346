<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">人员薪资</div>
      <div class="back" @click="goback">返回上一页</div>
    </div>
    <div class="from-box">
      <!-- 1 -->
      <div class="line-box">
        <div class="line-title">基本薪酬</div>
        <el-input v-model="value" placeholder="基本薪酬" />
      </div>
      <div class="line-box">
        <div class="line-title">住房补贴标准</div>
        <el-input v-model="value" placeholder="住房补贴标准" />
      </div>
      <div class="line-box">
        <div class="line-title">国内其他津补贴</div>
        <el-input v-model="value" placeholder="国内其他津补贴" />
      </div>
      <!-- 2 -->
      <div class="line-box">
        <div class="line-title">疗养费标准</div>
        <el-input v-model="value" placeholder="疗养费标准" />
      </div>
      <div class="line-box">
        <div class="line-title">年终奖标准</div>
        <el-input v-model="value" placeholder="年终奖标准" />
      </div>
      <div class="line-box">
        <div class="line-title">公积金缴纳基数</div>
        <el-input v-model="value" placeholder="公积金缴纳基数" />
      </div>
      <!-- 3 -->
      <div class="line-box">
        <div class="line-title">社保缴纳基数</div>
        <el-input v-model="value" placeholder="社保缴纳基数" />
      </div>
      <div class="line-box">
        <div class="line-title">岗位补贴（岗位工资）标准</div>
        <el-input v-model="value" placeholder="岗位补贴（岗位工资）标准" />
      </div>
      <div class="line-box">
        <div class="line-title">地区补贴（技术津贴）标准</div>
        <el-input v-model="value" placeholder="地区补贴（技术津贴）标准" />
      </div>
      <!-- 4 -->
      <div class="line-box">
        <div class="line-title">野外工作补贴（生活津贴）标准</div>
        <el-input v-model="value" placeholder="野外工作补贴（生活津贴）标准" />
      </div>
      <div class="line-box">
        <div class="line-title">家庭补贴-计发时间</div>
        <el-input v-model="value" placeholder="家庭补贴-计发时间" />
      </div>
      <div class="line-box">
        <div class="line-title">家庭补贴标准</div>
        <el-input v-model="value" placeholder="家庭补贴标准" />
      </div>
      <!-- 5 -->
      <div class="line-box">
        <div class="line-title">假日加班工资标准</div>
        <el-input v-model="value" placeholder="假日加班工资标准" />
      </div>
      <div class="line-box">
        <div class="line-title">节日加班工资标准</div>
        <el-input v-model="value" placeholder="节日加班工资标准" />
      </div>
      <div class="line-box">
        <div class="line-title">加点标准</div>
        <el-input v-model="value" placeholder="加点标准" />
      </div>
      <!-- 6 -->
      <div class="line-box">
        <div class="line-title">其他收益标准</div>
        <el-input v-model="value" placeholder="其他收益标准" />
      </div>
      <div class="line-box">
        <div class="line-title">绩效奖金-比例标准</div>
        <el-input v-model="value" placeholder="绩效奖金-比例标准" />
      </div>
      <div class="line-box">
        <div class="line-title">绩效奖金-定额单价</div>
        <el-input v-model="value" placeholder="绩效奖金-定额单价" />
      </div>
      <!-- 7 -->
      <div class="line-box">
        <div class="line-title">考勤模式</div>
        <el-input v-model="value" placeholder="考勤模式" />
      </div>
      <div class="line-box">
        <div class="line-title">月预支奖金标准</div>
        <el-input v-model="value" placeholder="月预支奖金标准" />
      </div>
      <div class="line-box">
        <div class="line-title">兑现奖励标准</div>
        <el-input v-model="value" placeholder="兑现奖励标准" />
      </div>
      <!-- 8 -->
      <div class="line-box">
        <div class="line-title">基本工资标准-外籍</div>
        <el-input v-model="value" placeholder="基本工资标准-外籍" />
      </div>
      <div class="line-box">
        <div class="line-title">食宿补贴标准-外籍</div>
        <el-input v-model="value" placeholder="食宿补贴标准-外籍" />
      </div>
      <div class="line-box">
        <div class="line-title">GOSI标准-外籍</div>
        <el-input v-model="value" placeholder="GOSI标准-外籍" />
      </div>
      <!-- 9 -->
      <div class="line-box">
        <div class="line-title">交通补贴标准-外籍</div>
        <el-input v-model="value" placeholder="交通补贴标准-外籍" />
      </div>
      <div class="line-box">
        <div class="line-title">医疗贴标准-外籍</div>
        <el-input v-model="value" placeholder="医疗贴标准-外籍" />
      </div>
      <div class="line-box">
        <div class="line-title">工龄补贴标准-外籍</div>
        <el-input v-model="value" placeholder="工龄补贴标准-外籍" />
      </div>
      <!-- 10 -->
      <div class="line-box">
        <div class="line-title">绩效奖金标准-外籍</div>
        <el-input v-model="value" placeholder="绩效奖金标准-外籍" />
      </div>
      <div class="line-box">
        <div class="line-title">基本工资（GOSI注册）</div>
        <el-input v-model="value" placeholder="基本工资（GOSI注册）" />
      </div>
      <div class="line-box">
        <div class="line-title">GOSI标准-外籍</div>
        <el-input v-model="value" placeholder="住房补贴-注册" />
      </div>
      <!-- 11 -->
      <div class="line-box">
        <div class="line-title">年度考核结果</div>
        <el-input v-model="value" placeholder="年度考核结果" />
      </div>
      <div class="line-box">
        <div class="line-title">两小时补助-外籍</div>
        <el-input v-model="value" placeholder="两小时补助-外籍" />
      </div>
      <div class="line-box">
        <div class="line-title">保险津贴</div>
        <el-input v-model="value" placeholder="保险津贴" />
      </div>
      <!-- 12 -->
      <div class="line-box">
        <div class="line-title">海外工作补贴</div>
        <el-input v-model="value" placeholder="海外工作补贴" />
      </div>
      <div class="line-box">
        <div class="line-title">保留工资</div>
        <el-input v-model="value" placeholder="保留工资" />
      </div>
      <div class="line-box">
        <div class="line-title">年金缴纳基数</div>
        <el-input v-model="value" placeholder="年金缴纳基数" />
      </div>
      <!-- 13 -->
      <div class="line-box">
        <div class="line-title">组(伙)长津贴</div>
        <el-input v-model="value" placeholder="组(伙)长津贴" />
      </div>
      <div class="line-box">
        <div class="line-title">高技术(技能)津贴</div>
        <el-input v-model="value" placeholder="高技术(技能)津贴" />
      </div>
      <div class="line-box">
        <div class="line-title">房租补贴</div>
        <el-input v-model="value" placeholder="房租补贴" />
      </div>
      <!-- 14 -->
      <div class="line-box">
        <div class="line-title">加班日工资</div>
        <el-input v-model="value" placeholder="加班日工资" />
      </div>
      <div class="line-box">
        <div class="line-title">南京市企业最低工资标准（月）</div>
        <el-input v-model="value" placeholder="南京市企业最低工资标准（月）" />
      </div>
      <div class="line-box">
        <div class="line-title">艰苦补贴系数</div>
        <el-input v-model="value" placeholder="艰苦补贴系数" />
      </div>
      <!-- 15 -->
      <div class="line-box">
        <div class="line-title">艰苦补贴基数</div>
        <el-input v-model="value" placeholder="艰苦补贴基数" />
      </div>
      <div class="line-box">
        <div class="line-title">GOSI扣减系数</div>
        <el-input v-model="value" placeholder="GOSI扣减系数" />
      </div>
      <div class="line-box">
        <div class="line-title">自定义项10</div>
        <el-input v-model="value" placeholder="自定义项10" />
      </div>
      <!-- 16 -->
      <div class="line-box">
        <div class="line-title">自定义项11</div>
        <el-input v-model="value" placeholder="自定义项11" />
      </div>
      <div class="line-box">
        <div class="line-title">自定义项12</div>
        <el-input v-model="value" placeholder="自定义项12" />
      </div>
      <div class="line-box">
        <div class="line-title">自定义项13</div>
        <el-input v-model="value" placeholder="自定义项13" />
      </div>
      <!-- 循环 -->
      <div class="line-box" v-for="item in 7" :key="item">
        <div class="line-title">自定义项{{ 13 + item }}</div>
        <el-input v-model="value" :placeholder="`自定义项${13 + item}`" />
      </div>
      <div class="buttons">
        <el-button class="pop-close" @click="goback">取消</el-button
        ><el-button class="pop-save" @click="addCompanies">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: null,
      value1: null,
    };
  },
  methods: {
    goback() {
      this.$router.push("/ordinary");
    },
    uploadAttachment() {},
    addCompanies(){},
  },

  created() {},
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .back {
      margin-left: 24px;
      color: #257eff;
      cursor: pointer;
    }
  }
  .from-box {
    width: 100%;
    min-height: calc(100% - 40px);
    background-color: #fff;
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    padding-top: 20px;
    box-sizing: border-box;
    justify-content: space-between;
    align-content: flex-start;
    .line-box {
      width: 30%;
      margin-bottom: 24px;
      .line-title {
        font-size: 14px;
        color: #100303;
        margin-bottom: 10px;
      }
      .el-select {
        width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .bulue-title {
        color: #257eff;
        cursor: pointer;
      }
    }
    .line-box2 {
      width: 24%;
    }
    .line-box3 {
      width: 49%;
    }
    .line-upload {
      width: 100%;
    }
    .upload-load {
      display: flex;
      height: 34px;
      //   margin-top: 24px;
      .left {
        width: 100%;
        border: 1px solid #d2d6de;
        border-right: none;
        .name {
          display: flex;
          align-items: center;
          height: 34px;
          color: #555;
          font-size: 14px;
          text-indent: 2px;
        }
      }
      .btn-up {
        padding: 0;
        margin: 0;
        border: none;
        width: 90px;
        flex-shrink: 0;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #3c8dbc;
        border: 1px solid #367fa9;
        color: #fff;
        font-size: 14px;
        .icon {
          margin-right: 5px;
        }
      }
      .disabled {
        cursor: no-drop;
      }
      .btn-up:hover {
        border-color: #204d74;
        background-color: #367fa9;
      }
    }
    .buttons {
      width: 100%;

      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}
.tableData2 >>> .el-table__header .cell {
  font-size: 14px !important;
  font-weight: bolder !important;
}

.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
</style>
